"use strict";
document.querySelector('html').classList.add('js')
document.querySelector('html').classList.remove('no-js')

// Navtoggler
document.querySelector('#navtoggler').addEventListener('click', function () {
    if (document.body.classList.contains('body--activenav')) {
        document.body.classList.remove('body--activenav');
    } else {
        document.body.classList.add('body--activenav');
    }
});

// Headerbar

window.addEventListener('scroll', () => {
    if (window.pageYOffset > 100) {
        document.querySelector('.headerbar').classList.add('scrolled');
    } else {
        document.querySelector('.headerbar').classList.remove('scrolled');
    }
});

// AOS - Initialize AOS Library

AOS.init({
    duration: 500,
    easing: 'ease-in-quad',
});

// Contentswitches

let contentswitches = document.querySelectorAll('.contentswitch');

if (contentswitches.length > 0) {
    for (let contentswitch of contentswitches) {
        // Variabels

        let count = 0;
        let contentimages = contentswitch.querySelectorAll('.contentswitch__images img');
        let contentitems = contentswitch.querySelectorAll('.contentswtich__contentitems > div');

        // Set Initial active Items
        contentimages[count].classList.add('active');
        contentitems[count].classList.add('active');

        // Add Click-Behaviour

        for (let contentitem of contentitems) {
            contentitem.addEventListener('click', (e) => {
                // Remove active classes
                contentimages[count].classList.remove('active');
                contentitems[count].classList.remove('active');

                count = [...contentitem.parentElement.children].indexOf(contentitem);

                // add new active classes
                contentimages[count].classList.add('active');
                contentitems[count].classList.add('active');
            })
        }
    }
}

// Singleblog layout
if (document.querySelectorAll('.singleblog').length > 0) {
    let headings = {};
    let i = 0;
    let lastMainHeading = false;

    for (let heading of document.querySelectorAll('.item h2, .item h3')) {
        let jumplink = document.createElement('span');

        jumplink.setAttribute('id', 'jumpheading' + i)
        jumplink.classList.add('anchorlink')
        heading.before(jumplink);

        if (heading.tagName === 'H2') {
            lastMainHeading = i
            headings[i] = {name: heading.textContent, children: {}, hasChildren: false}
        } else {
            headings[lastMainHeading].hasChildren = true
            headings[lastMainHeading].children[i] = {name: heading.textContent}
        }

        i++
    }

    for (const id in headings) {
        let heading = headings[id]

        let childrenHtml = false

        if (heading.hasChildren) {
            childrenHtml = document.createElement('ul');

            for (const childId in heading.children) {
                let child = heading.children[childId]

                let childLink = document.createElement('a')
                childLink.setAttribute('href', '#jumpheading' + childId)
                childLink.innerHTML = child.name

                let childItem = document.createElement('li')
                childItem.appendChild(childLink)

                childrenHtml.appendChild(childItem)
            }
        }

        let headingLink = document.createElement('a')
        headingLink.setAttribute('href', '#jumpheading' + id)
        headingLink.innerHTML = heading.name

        let headingItem = document.createElement('li')

        headingItem.appendChild(headingLink)

        if (childrenHtml) {
            headingItem.appendChild(childrenHtml)
            headingItem.classList.add('parent');
        }

        document.querySelector('.singleblog__nav ul').appendChild(headingItem)
    }
    document.querySelector('.singleblog__nav__toggler').addEventListener('click', () => {
        document.querySelector('.singleblog__nav').classList.toggle('opened');
        if (document.querySelector('.singleblog__nav').classList.contains('opened') === true) {
            document.querySelector('.singleblog__nav__toggler').innerText = 'Schließen';
            console.log('open');
        }
        if (document.querySelector('.singleblog__nav').classList.contains('opened') === false) {
            document.querySelector('.singleblog__nav__toggler').innerText = 'Seitennavigation Öffnen';
            console.log('closed');
        }
    })

}

// Singleblog Layout Highlighting and Nav Behaviour

function isElementInViewport (el) {
    const rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}

// once on pageload

if (document.querySelector('.singleblog__nav__itemlist a')) {
    document.querySelector('.singleblog__nav__itemlist a').classList.add('inviewport');
}

window.addEventListener('scroll', () => {
    let singleblogHeadings = document.querySelectorAll('.anchorlink');

    for (let singleblogHeading of singleblogHeadings) {
        if(isElementInViewport(singleblogHeading) === true) {
            let navitem = document.querySelector('.singleblog__nav__itemlist a[href="#' + singleblogHeading.id + '"]');

            if(navitem) {
                // Remove old active items
                for (let activeItem of document.querySelectorAll('.singleblog__nav__itemlist li a.inviewport')) {
                    activeItem.classList.remove('inviewport');
                }

                navitem.classList.add('inviewport');

                if (navitem.parentNode.parentNode.parentNode.nodeName === "LI") {
                    navitem.parentNode.parentNode.parentNode.firstChild.classList.add('inviewport')
                }

                return;
            }
        };
    }
})

// Detect Safari and add pageclass

window.addEventListener('DOMContentLoaded', () => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {

        } else {
            document.body.classList.add('isSafari');
        }
    }
})

window.addEventListener('scroll', () => {
    if (document.body.classList.contains('isSafari') === true && document.querySelectorAll('.singleblog') != null) {
        if (window.scrollY > 180) {
            document.querySelector('.singleblog__nav').style.transform = "translateY(0rem)";
        } else {
            document.querySelector('.singleblog__nav').style.transform = "translateY(0rem)";
        }
    }
})

// Fix Singleblog height if Footer in View

function isVisible (ele) {
    const { top, bottom } = ele.getBoundingClientRect();
    const vHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (
      (top > 0 || bottom > 0) &&
      top < vHeight
    );
}

if (document.querySelectorAll('.singleblog').length > 0) {
    if(window.outerWidth > 992) {
        window.addEventListener('scroll', () => {
            if (isVisible(document.querySelector('footer'))) {
                document.querySelector('.singleblog__nav').style.opacity = '0';
            } else {
                document.querySelector('.singleblog__nav').style.opacity = '1';
            }
        });
    }
}

// Set Toolbarheight on Pageload / Resize
let updateToolbarHeight  = () => {
    if(document.querySelector('.toolbar').clientHeight) {
        document.documentElement.style.setProperty('--toolbarheight', document.querySelector('.toolbar').clientHeight + "px");
    }
}
document.addEventListener('DOMContentLoaded', updateToolbarHeight);
window.addEventListener('resize', updateToolbarHeight);